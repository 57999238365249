import vueI18n from '@/plugins/vue-i18n';

export const numberToKMGT = (num, precision = 2, fallback = null) => {
  const map = [
    { suffix: vueI18n.tc('Common.Kmg.T'), threshold: 1e12 },
    { suffix: vueI18n.tc('Common.Kmg.G'), threshold: 1e9 },
    { suffix: vueI18n.tc('Common.Kmg.M'), threshold: 1e6 },
    { suffix: vueI18n.tc('Common.Kmg.K'), threshold: 1e3 },
    { suffix: '', threshold: 1 }
  ];

  const found = map.find(x => Math.abs(num) >= x.threshold);

  if (found) {
    const divided = num / found.threshold;

    if (divided % 1) {
      return divided.toFixed(precision) + found.suffix;
    }

    return divided + found.suffix;
  }

  return fallback ?? num;
};